<template>
    <qrcode-vue :value="JSON.stringify(value)" :size="size" :level="level"/>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
    name: "QrCode",
    props: {
        value: {type: Object, default: () => null},
        size: {type: String, default: '150'},
        level: {type: String, default: 'H'},
    },
    components: {
        QrcodeVue,
    },
    data() {
        return {}
    },
}
</script>

<style scoped>

</style>
