<template>
    <b-modal id="confirmation-dialog" size="lg"
             no-close-on-esc
             no-close-on-backdrop
             v-on:ok="$emit(ok_event, data)"
             v-on:cancel="$emit(cancel_event, data)">

        <template #modal-title>
            <font-awesome-icon v-if="type === 'delete'" :icon="['fas', 'trash-alt']"/>
            <span class="ml-3">{{ title }}</span>
        </template>

        <p>{{ message }}</p>

        <template #modal-footer="{ ok, cancel }">
            <b-button pill variant="danger" class="pl-5 pr-5 mr-2"
                      v-on:click="ok()">
                {{ ok_text }}
            </b-button>
            <b-button pill variant="dark" class="pl-5 pr-5"
                      v-on:click="cancel()">
                {{ cancel_text }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
export default {
    name: "ConfirmationDialog",
    props: {
        type: {type: String, default: ''},
        title: {type: String, default: ''},
        message: {type: String, default: ''},
        ok_event: {type: String, default: 'ok'},
        ok_text: {type: String, default: 'OK'},
        cancel_event: {type: String, default: 'cancel'},
        cancel_text: {type: String, default: 'Cancel'},
        data: {type: Object, default: () => null},
    }
}
</script>

<style scoped>
</style>
